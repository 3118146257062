
/* ------------------------------------------------------------------
Typography
------------------------------------------------------------------- */

a {
	color: rgba(17, 17, 17, 0.55);
}

a:hover, a:focus {
	text-decoration: none;
	color: @primary;
	outline: 0;
}

.bg-dark a {
	color: #aaa;
}

.bg-dark a:hover,
.bg-dark a:focus {
	color: #fff;
}

.text-light {
	color: rgba(255, 255, 255, 0.7);
}

.text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6,
.text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6 {
	color: #fff;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	line-height: 1.4;
	font-weight: 400;
	color: @primary;
}

h1, .h1 {
	font-size: 28px;
}

h2, .h2 {
	font-size: 24px;
}

h3, .h3 {
	font-size: 18px;
}

h4, .h4 {
	font-size: 16px;
}

h5, .h5 {
	font-size: 14px;
}

h6, .h6 {
	font-size: 12px;
}

p, ol, ul, blockquote {
	margin: 0 0 20px;
}

blockquote {
	border-color: #f7f7f7;
	font-size: inherit;
}

blockquote.serif-quote {
	border: 0;
	padding-left: 0;
	padding-right: 0;
}

.font-alt {
	font-family: "Dosis", sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
}

.font-serif {
	font-family: "Libre Baskerville", "Times New Roman", sans-serif;
	letter-spacing: 1px;
	font-style: italic;
}

.rotate {
	text-shadow: none !important;
}

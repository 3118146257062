.view {
	height: auto;
	float: left;
	margin: 0 5px;
	position: relative;
	cursor: default;
	background: #fff;
	display: flex;
	align-content: space-between;
	align-items: center;
	-webkit-box-shadow: 2px 2px 4px #c3c3c3;
	-moz-box-shadow: 2px 2px 4px #c3c3c3;
	box-shadow: 2px 2px 4px #c3c3c3;
}
.view .holder {

}
.view .mask,.view .content {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	max-width: 100%;
}
.view img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	margin: 0 auto;
}
.view h2 {
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	position: relative;
	font-size: 22px;
	line-height: normal;
	letter-spacing: normal;
	padding: 5px 10px;
	background: rgba(0, 0, 0, 0.8);
	margin: 8% 0 0 0;
	min-height: 65px;
	vertical-align: middle;
}
.view p {

	position: relative;
	color: #fff;
	padding: 10px 30px 0 30px;

	&.description {
		overflow: hidden;
		white-space: normal;
		text-overflow: ellipsis;
		word-wrap: break-word;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3; /* number of lines to show */
		line-height: 25px;        /* fallback */
		max-height: 150px;
		font-size: 13px;/* fallback */
		text-shadow: 1px 1px 1px rgba(0,0,0,0.7);

	}

	&.class {
		max-width: 65%;
		overflow: hidden;
		line-height: 15px;        /* fallback */
		position: absolute;
		bottom: 2px;
		left: 2px;
		font-style: italic;
		font-size: 12px;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.7);

	}
}

.popup-container {
	img {
		cursor: zoom-in;
	}
}
.view .sku {
	position: absolute;
	bottom: 2px;
	right: 2px;
	color: @purple;
	font-weight: bold;
}
.view a.info {
	margin-top: 10px;
	display: inline-block;
	text-decoration: none;
	padding: 7px 14px;
	background: #000;
	color: #fff;
	text-transform: uppercase;
	-webkit-box-shadow: 0 0 1px #000;
	-moz-box-shadow: 0 0 1px #000;
	box-shadow: 0 0 1px #000;
}
.view a.info:hover {
	-webkit-box-shadow: 0 0 5px #000;
	-moz-box-shadow: 0 0 5px #000;
	box-shadow: 0 0 5px #000;
}

.view-first img {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.view-first .mask {
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	background-color: rgba(219,127,8, 0.65);
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.view-first h2 {
	-webkit-transform: translateY(-100px);
	-moz-transform: translateY(-100px);
	-o-transform: translateY(-100px);
	-ms-transform: translateY(-100px);
	transform: translateY(-100px);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.view-first p {
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-o-transform: translateY(100px);
	-ms-transform: translateY(100px);
	transform: translateY(100px);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.view-first:hover img {
	//-webkit-transform: scale(1.1,1.1);
	//-moz-transform: scale(1.1,1.1);
	//-o-transform: scale(1.1,1.1);
	//-ms-transform: scale(1.1,1.1);
	//transform: scale(1.1,1.1);
}
.view-first a.info {
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.view-first:hover .mask {
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}
.view-first:hover h2,
.view-first:hover p,
.view-first:hover a.info {
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-o-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
}
.view-first:hover p {
	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	-ms-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.view-first:hover a.info {
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	transition-delay: 0.2s;
}



@import "helper";


@yellow: #D6CC42; /* BRIGHT YELLOW */
@dark_yellow: #B0A41E; /* DARK YELLOW */
@orange: #9C7300; /* ORANGE */
@blue: #673ab7; /* ROYAL BLUE */
@purple: #50308A; /* ROYAL PURPLE */
// OVERRIDE BOOTSTRAP VARIABLES
@brand-primary:         @blue; // #337ab7
@brand-success:         @orange;
@brand-info:            darken(@purple, 6.5%);
@brand-warning:         @yellow;
@brand-danger:          #e51c23;

@primary: #323233;

@import url("https://fonts.googleapis.com/css?family=Dosis");
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400italic");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700");

@import  "../../vendor/bootstrap/less/variables";
@import  "../../vendor/bootstrap/less/mixins";
@import  "../../vendor/bootstrap/less/responsive-utilities";
// Import template styles
@import (inline) "../../template2/bootstrap/css/bootstrap.css";
@import (inline) "../../template2/css/font-awesome.css";
@import (inline) "../../template2/css/ionicons.css";
@import (inline) "../../template2/css/simpletextrotator.css";
@import (inline) "../../template2/css/magnific-popup.css";
@import (inline) "../../template2/css/owl.carousel.css";
@import (inline) "../../template2/css/superslides.css";
@import (inline) "../../template2/css/vertical.css";
@import (inline) "../../template2/css/animate.css";

@import "template/general";
@import "template/typography";
@import "template/components";

@import "helper";
@import "gallery";
@import "grid";
@import "magnificPopup";
@import "_animate";
@import "print";



@media all and (max-width: 960px){
	.navigation-container {

		.navbar-header {
			margin-left: 5px;
			.navbar-brand {
				width: 150px;
			}
		}
		#icons-navbar {
			margin-top: 15px;
			.megamenu-content {
				top: 84px;
				position: absolute;
				padding: 0
			}
		}
	}
	.navbar-custom {
		padding-top: 0!important;
		padding-bottom: 0!important;
	}
}

.sb-siblings-hide {
	display: none!important;
}

.sb-search {
	position: relative;
	width: 0%;
	min-width: 60px;
	height: 80px;
	float: right;
	overflow: hidden;
	-webkit-transition: width 0.3s;
	-moz-transition: width 0.3s;
	transition: width 0.3s;
	-webkit-backface-visibility: hidden;
}

.sb-search-input {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	outline: none;
	background: #fff;
	width: 100%;
	height: 80px;
	margin: 0;
	z-index: 10;
	padding: 20px 65px 20px 10px;
}

.sb-icon-search,
.sb-search-submit  {
	width: 60px;
	height: 80px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	line-height: 80px;
	text-align: center;
	cursor: pointer;
}

.sb-search-submit {
	background: #fff; /* IE needs this */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
	filter: alpha(opacity=0); /* IE 5-7 */
	opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}

.sb-icon-search {
	color: @primary;
	z-index: 11;
	speak: none;
}



/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
	width: 100%;
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {

	&:before {
		color: #111111;
	}
	z-index: 11;
}
.sb-search.sb-search-open .sb-icon-search{
	z-index: 91;
}

.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
	z-index: 90;
}

.navigation-container {
	#navbar-list {
		margin-top: 15px;
	}
}

.breadcrumb {
	padding: 0;
	background-color: rgb(212, 212, 214);
	margin-bottom: 0;
	margin-top: 10px;
	.container {
		padding: 0;
	}
	ul {
		padding: 0;
		li {
			&:before {
				content: '/ ';
			}
			&:first-child:before {
				content: '';
			}
			display: inline;
			color: #888;
			* {
				color: #888;
			}
			a:hover {
				color: @blue;
			}
			&.active {
				color: @blue;
			}
		}
	}
}

.sticky {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 500;
}

.site-wrapper {
	padding-top: 110px;
}


.antique-ad, #mainImage, .post-media, .cart_img {
	border: 1px solid rgba(46, 52, 54, 0.10);
}
.header-border-top {
	margin-top: 10px;
	border-top: 1px solid rgba(218, 218, 218, 0.57);
	*, a {
		text-transform: none!important;
		color: @blue!important;
	}
}

.antique-ad {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	.work-status {
		font-size: 10px;
		padding: 15px 10px;
	}
	span {
		color: @primary;
		cursor: pointer;
		display: table;
		height: 101%;
		width: 101%;
		right: -1px;
		left: -1px;
		position: absolute;
		top: -1px;
		bottom: -1px;
		opacity: 0;
		-webkit-transition: opacity 500ms;
		-moz-transition: opacity 500ms;
		-o-transition: opacity 500ms;
		transition: opacity 500ms;
		&:before {
			opacity: 0;
			-webkit-transition: opacity 500ms;
			-moz-transition: opacity 500ms;
			-o-transition: opacity 500ms;
			transition: opacity 500ms;
		}
		span {
			display: flex;
			align-items: center;
			text-align: center;
			vertical-align: middle;
			* {
				color: @primary;
				width: 100%;
			}
		}
	}

	&:hover {
		span, span:before, .work-status {
			opacity: 1.0;
		}
	}
}

.text-warning {
	color: @yellow!important;
}

.img-background-center {
	background-repeat: no-repeat;
	background-position: center;
	background:white;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center
}

hr {
	border-bottom: 1px solid rgba(46, 52, 54, 0.20);
	margin: 20px!important;
	height: 2px;
	padding: 0 45px;
	border-top: none!important;
}
.inline-block {
	display: inline-block;
	margin: 0 25px;
	height: 100%;
}
.footer-content, .flex-content {
	justify-content: space-around;
	display: flex;
	align-items: center;
	align-content: center;
}
hr.white {
	border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.gallery {
	img{
		border: 1px solid rgba(46, 52, 54, 0.10);
	}
}

.navbar-topbar {
	background: #f5f5f5 !important;
	min-height: 30px!important;
	li a {
		padding: 5px!important;
	}
	font-size: 10px;
}

.navbar {
	margin-bottom: 0!important;
	border-radius: 0!important;
}

a.navbar-brand {
	padding:0!important;
	height: 80px;
	width: auto;
	display: inline-block;
	line-height: 80px;
	width: 200px;
	position: relative;
	* {
		vertical-align: middle;
		display: inline-block;
	}
}
/* MEGAMENU STYLE
-------------------------------------------------- */
.megamenu .nav,
.megamenu .collapse,
.megamenu .dropup,
.megamenu .dropdown {
	position: static;
}
.megamenu .container {
	position: relative;
}
.megamenu .dropdown-menu {
	left: auto;
}
.megamenu .megamenu-content {
	padding: 15px;
}
.megamenu .megamenu-content h3{
	margin-top: 0;
	color: #428bca;
	font-size: 18px;
}
.megamenu .dropdown.megamenu-fw .dropdown-menu {
	left: 0;
	right: 0;
	top: 89px;
}

.purple {
	color: @purple!important;
}
.no-decoration, .no-decoration:hover, .no-decoration:focus, .no-decoration:active {
	text-decoration: none;
	color: inherit!important;
}
.inline {
	display: inline;
}


.table-white td{
	vertical-align: middle;
	background-color: white!important;
	.actions {
		float: right;
	}
}

.works-grid,
.works-grid .work-item {
	/* change duration value to whatever you like */
	-webkit-transition-duration:1.2s;
	-moz-transition-duration:1.2s;
	-ms-transition-duration:1.2s;
	-o-transition-duration:1.2s;
	transition-duration:1.2s;
}

.works-grid {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}


.work-item {
	opacity: 0.0;

	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-ms-transition-property: opacity;
	-o-transition-property: opacity;
	transition-property: opacity;
	a:after {
		border: 1px solid rgba(46, 52, 54, 0.10);
		&:hover {
			border: none!important;
		}
	}

	&.before {
		position: absolute;
		top:45%;
		left:45%;
		content: "Loading...";
	}

}


.category-row-fixed {
	position: fixed;
	width: 100%;
	top: 130px;
	z-index: 500;
	border-top: 1px solid rgba(104, 104, 104, 0.3);
}

.work-item.shown {
	opacity: 1.0;
	-webkit-animation: fadeIn 1.5s ease-in-out forwards;
	animation: fadeIn 1.5s ease-in-out forwards;
}

.works-grid .work-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property:    -moz-transform, opacity;
	-ms-transition-property:     -ms-transform, opacity;
	-o-transition-property:      -o-transform, opacity;
	transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.works-grid.no-transition,
.works-grid.no-transition .work-item,
.works-grid .work-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}

.text-bold {
	font-weight: 600;
}
body {
	.wrapper {
		padding: 0!important;
	}
}
.navbar-right, .row {
	clear: none!important;
	display: block;
}

.overlay-menu-nav {
	background: transparent!important;
	a {
		font-weight: 200!important;
	}
}

.item-figure {
	position: relative;
	height: 400px;
	.item-image {
		position: relative;
		height: 80%!important;
		max-width: 100%;
		display: flex;
		align-items: center;
		align-content: center;
		text-align: center;
		img {
			max-width: 90%;
			max-height: 90%;
			display: block;
			margin: 0 auto
		}
	}
	.item-info {
		height: 20%!important;
	}
}

nav{
	background: white!important;
}

.img-responsive {
	margin: 0 auto;
	width: 100%;
}

footer a:not(.fa), .btn-d {
	&:hover, &:active, &:focus{
		color: @yellow!important;
		* {
			color: @yellow!important;
		}
	}
}
.black-bg {
	background-color: black;
	* {
		color: white;
	}
}
.btn-white {
	background-color: white;
	color: @primary!important;
	* {
		color: @primary!important;
	}
}
.clickable {
	cursor: pointer;
}
#mainImage {
	cursor: zoom-in;
}

.text-info{
	color: #344EC4;
}
.text-primary{
	color: @blue;
}

.category-row {
	width: 100%;
	background-color: white;
	height: 30px;


	ul {
		list-style: none;
		display: flex;
		width: 100%;
		justify-content: space-around;
		align-content: stretch;
		align-items: stretch;
		li {
			display: inline;
			text-align: center;
			height: 30px;
			&.active, &:hover {
				background-color: #d6d6d7;
			}
			&.active {
				color: #000;
			}
			* {
				color: inherit!important;
				margin: 0 15px;
				padding: 0;
				line-height: 30px;
				display: inline;
				font-size: 13px;
				letter-spacing: normal;
			}
		}

		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;

		@media (min-width: 768px) {
			width: 750px;
		}
		@media (min-width: 992px) {
			width: 970px;
		}
		@media (min-width: 1200px) {
			width: 1170px;
		}
	}
}

@media (max-width: 768px) {
	h1, h2, h3, h4, h5, h6, p {
		font-size: 90%;
	}
	.footer-content, .flex-content {
		display: block;
	}
	.module {
		padding: 60px 0;
	}
	.work-caption {
		padding: 5px;
	}
	.work-title {
		font-size: 12px;
	}
}

// HOMEPAGE REQUEST DESKTOP TEST
.request-desktop-container { display: none; }
@media only screen and (max-width: 768px) {
	.request-desktop-container { display: block; }
}

// PORTFOLIO ALERT
.portfolio-alert { 
	font-size: 1.25em; 
	a { text-decoration: underline; }
}

// MOBILE MODIFICATIONS 09/25/16
@media only screen and (max-width: 768px) {
	// MOBILE ANTIQUE TILE TAGS
	.work-status { opacity: 1; }
	// MOBILE PORTFOLIO CENTER ALIGN
	
}
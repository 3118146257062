

/* ------------------------------------------------------------------
General Styles
------------------------------------------------------------------ */

body {
	background: #fff;
	font: 400 14px/1.8 "Open Sans", sans-serif;
	color: #777;
	overflow-x: hidden;
	-ms-overflow-style: scrollbar;
}

img {
	max-width: 100%;
	height: auto;
}

iframe {
	border: 0;
}

/* ------------------------------------------------------------------
Selection
------------------------------------------------------------------ */

::-moz-selection {
	background: #000;
	color: #fff;
}

::-webkit-selection {
	background: #000;
	color: #fff;
}

::selection {
	background: #000;
	color: #fff;
}

/* ------------------------------------------------------------------
Transition elsements
------------------------------------------------------------------- */

a,
.btn,
.navbar a {
	-webkit-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
	-moz-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
	-o-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
	transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.overlay-menu {
	-webkit-transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	-ms-transition: all 0.4s ease-in-out 0s;
	-o-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}

/* ------------------------------------------------------------------
Reset box-shadow
------------------------------------------------------------------- */

.btn,
.well,
.panel,
.progress,
.form-control, .form-control:hover, .form-control:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
}

/* ------------------------------------------------------------------
Reset border-radius
------------------------------------------------------------------- */

.well, .label, .alert,
.modal-content {
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;
}

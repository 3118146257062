.include-for-print {
	display: none;
}
.print-logo {
	display: none;
}
@media print {

	.site-wrapper {
		padding-top: 0!important;
	}
	* {
		-webkit-print-color-adjust: exact;
	}
	.module-header, .module-related, footer, .prettySocial, .btn, .divider, .scroll-up, .exclude  {
		display: none!important;
	}

	.wrapper {
		background-color: transparent;

	}
	.print-logo {
		margin-top: 20px;
		display: block;
		img {
			width: 175px!important;
		}
	}
	.include-for-print {

		padding-top: 100px;
		&:first-of-type {
			page-break-before: avoid;
			padding: 25px 15px;
			img {
				width: 50%;
			}
		}
		display: block;

		.flex-row {
			padding: 20px 0;
			.flex-content;
			justify-content: space-around;
		}
	}

	.printable {
		.print-row {
			width: 100%;
		}
	}


	@page { margin: 0.5cm; }
	body { margin: 0.5cm 1.6cm; }
	.bg-white {
		position: relative;
		margin: 0!important;
	}

	a[href]:after {
		content: none !important;
	}

}
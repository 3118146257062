#slides {
	position: relative;
}

#slides .slides-container {
	display: none;
}

#slides .scrollable {
	*zoom: 1;
	position: relative;
	top: 0;
	left: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	height: 100%;
}

#slides .scrollable:after {
	content: "";
	display: table;
	clear: both;
}

.slides-navigation {
	margin: -35px auto 0;
	position: absolute;
	z-index: 3;
	top: 50%;
	width: 100%;
}

.slides-navigation a {
	position: absolute;
	background: #fff;
	display: block;
	height: 40px;
	width: 40px;
	top: 50%;
	margin: -20px 10px 0;
	padding: 0;
	opacity: 0;
	text-align: center;
	line-height: 40px;
	font-size: 17px;
	color: #111;
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	-webkit-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
	   -moz-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
	     -o-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
	        transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.slides-navigation a:focus {
	color: #111;
}

.slides-navigation a.prev {
	left: -20px;
}

.slides-navigation a.next {
	right: -20px;
}

#slides:hover a.prev {
	opacity: 0.5;
	left: 0px;
}

#slides:hover a.next {
	opacity: 0.5;
	right: 0px;
}

#slides:hover a.prev:hover,
#slides:hover a.next:hover {
	opacity: 0.8;
	color: #111;
}

.slides-pagination {
	position: absolute;
	z-index: 3;
	bottom: 10px;
	text-align: center;
	width: 100%;
}

.slides-pagination a {
	position: relative;
	background: rgba(255, 255, 255, 0.7);
	display: inline-block;
	overflow: hidden;
	height: 6px;
	width: 6px;
	margin: 6px 4px;
	text-indent: -200%;
	z-index: 1000;
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	-webkit-box-shadow: 0 0 1px rgba(17, 17, 17, 0.4);
	   -moz-box-shadow: 0 0 1px rgba(17, 17, 17, 0.4);
	     -o-box-shadow: 0 0 1px rgba(17, 17, 17, 0.4);
	        box-shadow: 0 0 1px rgba(17, 17, 17, 0.4);
	-webkit-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	   -moz-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	     -o-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	        transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.slides-pagination a.current {
	-webkit-transform: scale(1.3);
	   -moz-transform: scale(1.3);
	     -o-transform: scale(1.3);
	        transform: scale(1.3);
}
